import styled, { css } from 'styled-components';

import { InputProps } from './';

export const InputFieldCss = css<StyledInputProps>`
  color: ${({ theme }) => theme.colors.nightBlue};
  width: 100%;
  height: 46px;
  padding: 0 8px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.babyBlue20};
  -webkit-appearance: none;
  line-height: normal;
  overflow: hidden;
  transition: 0.15s ease;

  &::placeholder {
    color: ${({ theme }) => theme.colors.nightBlue60};
  }

  &:active,
  &:focus {
    caret-color: ${({ theme }) => theme.colors.nightBlue};
    border-color: ${({ theme }) => theme.colors.nightBlue};
    outline: none;
  }

  &[readonly],
  &[disabled] {
    border-color: #e6e6e6;
    color: #cccccc;
    background-color: #f2f2f2;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.pomeloRed};

      &:active,
      &:focus {
        caret-color: ${({ theme }) => theme.colors.pomeloRed};
        border-color: ${({ theme }) => theme.colors.pomeloRed};
        outline: none;
      }
    `};

  ${({ as }) =>
    as === 'textarea' &&
    css`
      max-width: 100%;
      min-height: 150px;
      padding: 8px;
      resize: vertical;
      overflow-y: scroll;
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: 48px;
    `}
`;

export type StyledInputProps = Pick<InputProps, 'autoFocus'> & {
  error?: boolean;
  hasIcon?: boolean;
  as?: 'textarea';
};

export const StyledInput = styled.input`
  ${InputFieldCss};
`;

export const InputIcon = styled.div<IconWrapperProps>`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 30px;
    max-height: 100%;
    height: 24px;
    width: 24px;
    fill: ${({ theme }) => theme.colors.nightBlue60};
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      opacity: 0.4;
    `};
`;

type IconWrapperProps = Pick<InputProps, 'readOnly'>;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      ${StyledInput} {
        padding: 0 48px 0 8px;
      }

      ${InputIcon} {
        left: auto;
        right: 12px;
      }
    `}
`;

type InputWrapperProps = Pick<InputProps, 'iconPosition'>;
