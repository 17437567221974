import styled, { css } from 'styled-components';

export const InputWithActionContainer = styled.div`
  position: relative;
`;

export const InputWithActionButton = styled.button(
  ({ theme }) => css`
    appearance: none;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 16px;
    top: 12px;
    padding: 0;

    @media (hover: hover) {
      &:hover {
        svg {
          fill: ${theme.colors.nightBlue60};
        }
      }
    }

    & > svg {
      height: 100%;
      width: 100%;
      transition: fill 0.15s ease;
      fill: ${theme.colors.nightBlue};
    }
  `,
);
