import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.label<CheckboxContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.lato};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

type CheckboxContainerProps = {
  isActive?: boolean;
  isSelected?: boolean;
  isFocusVisible?: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 24px;
  width: 24px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.babyBlue};
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease;
  margin-right: 24px;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.babyBlue60};
    }
  }

  & svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.white};
  }

  ${({ isRadio }) => css`
    border-radius: ${isRadio ? '100%' : '8px'};
  `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.babyBlue};
    `}
`;

type ContainerProps = {
  isActive?: boolean;
  isRadio?: boolean;
};

export const Circle = styled.div(
  ({ theme }) => css`
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: ${theme.colors.white};
  `,
);
