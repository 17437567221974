import * as React from 'react';

import { Input, InputProps } from '../Input';
import { InputWithActionButton, InputWithActionContainer } from './styled';

export const InputWithAction = ({ actionIcon, action, ...otherProps }: InputWithActionProps) => {
  const ActionIcon = actionIcon as React.ReactElement;

  return (
    <InputWithActionContainer>
      <Input {...otherProps} />
      <InputWithActionButton onClick={action} type="button">
        {ActionIcon}
      </InputWithActionButton>
    </InputWithActionContainer>
  );
};

type InputWithActionProps = InputProps & {
  actionIcon: React.ReactNode;
  action: () => void;
};
